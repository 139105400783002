/**
 * @file 宠物小程序后台操作系统工具方法合集
 * @author 郄晓晖
 * @date 2020/10/12
 * @updateTime 2020/12/14
 * */
import axios from 'axios';
import qs from 'qs';
import html2canvas from "html2canvas";
import {message} from "antd";
const config = require('../config');

// 初始化axios序列配置
const $axios = axios.create({
  headers: {
    'content-type': 'application/x-www-form-urlencoded'
  },
  baseURL: config.BASE_URL,
  // `paramsSerializer` 是一个负责 `params` 序列化的函数
  paramsSerializer: function (params){
    return qs.stringify(params);
  },
  withCredentials: true,
  // `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
  timeout: 0
})

$axios.interceptors.response.use((res) => {
  const {data: {code, msg}} = res;
  if(code === 10004 || code === 10005 || code === 10008){
    localStorage.clear();
    window.location.hash = '/login';
    message.error(msg);
  } else{
    return res;
  }
}, (err) => {
  return err;
})

/**
 * @function 初始化数据方法
 * */

export const init = function(){
  this.setState({
    isLoading: true
  })
  request('GET', this.props.requestMap[this.state.type].inquire, {
    ...this.state.filterCondition
  }).then(res => {
    const {count, list} = res;
    this.setState({
      list,
      count,
      isLoading: false
    })
  }).catch(err => {
    message.error(err);
  })
}

/**
 * @function 文件上传方法
 * @param {Object} target - 要操作的组件对象
 * @param {Object} file - 要进行上传的文件
 * @return {Promise} 返回一个Promise对象，上传成功的话，返回图片的链接，上传失败的话返回失败原因
 * */

export const upLoad= (target, file) => {
  return new Promise((resolve, reject) => {
    target.props.ossClass.setOssFile(file).then(res => {
      const updateFile = target.props.ossClass.getOssFiles();
      // 上传单图时候的
      const url = `https://${target.props.ossInfo.bucket}.${target.props.OSS}/${updateFile[updateFile.length-1].name}`;
      target.props.ossClass.uploadAll().then(res => {
        target.props.ossClass.clearOssFiles().then((res) => {
          resolve(url);
        })
      }).catch(err => {
        reject(err)
      })
    });
  })
}

/**
 * 对图片文件格式，大小进行验证
 * @param {Object} file - 上传的文件对象
 * @return {Object} 返回一个状态对象，message为提示内容,type为上传失败的类型，status表示文件是否通过校验
 * */

export const checkImg = (file) => {
  console.log(file);
  const returnInfo = {};
  // 判断文件格式
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' ||  file.type === 'video/mp4';
  // 判断文件大小
  const isLt2M = file.size / 1024 / 1024 < 2;
  // 验证图片格式
  if (!isJpgOrPng) {
    returnInfo.message = '图片格式只能为JPG或PNG或GIF!视频格式只能为mp4';
    returnInfo.type = 'isJpgOrPng';
    returnInfo.status = false;
    return returnInfo
  }
  // 验证图片大小
  else if (!isLt2M) {
    returnInfo.message = '视频或图片不能大于2M!';
    returnInfo.type = 'isLt2M';
    returnInfo.status = false;
    return returnInfo
  }
  else {
    returnInfo.status = true;
    return returnInfo
  }
}

/**
 * @function 请求数据方法
 * @param {String} type - 请求类型 GET | POST
 * @param [url] url - 请求接口的链接
 * @param {Object} data - 请求携带的数据
 * @param {Object} configs - 请求的配置文件
 * @return {Promise} - 返回一个包含请求结果的Promise对象
 * */

export const request = function (type, url, data = {}, configs = {}){
  if(localStorage.getItem('ktz') && $axios.defaults.headers.common['token']!== JSON.parse(localStorage.getItem('ktz')).token){
    $axios.defaults.headers.common['token'] = JSON.parse(localStorage.getItem('ktz')).token;
  }
  if(type === 'POST'){
    // todo 1.判断是否有token验证 2.
    return new Promise((resolve, reject) => {
      $axios.post(url, qs.stringify(data) ,configs).then(res => {
        const {data: {success, msg, data}} = res;
        success ? resolve (data) : reject (msg)
      }).catch(err => {
        reject(err);
      });
    })
  }else{
    return new Promise((resolve, reject) => {
      $axios.get(url, {
        params: {
          ...data
        }
      }).then(res => {
        const {data: {success, msg, data}} = res;
        success ? resolve (data) : reject (msg)
      }).catch(err => {
        reject(err);
      });
    })
  }
}

/**
 * @function pagination - 分页功能，对数据进行重新计算 && 催动页面渲染
 * @param {Number} key - 当前请求的页面的页码
 * */

export const pagination = function(key){
  request('GET',this.props.requestMap[this.state.type].inquire, {
    ...this.state.filterCondition,
    page: key
  }).then(res => {
    if(res.list.length === 0){
      this.setState({
        filterCondition: {
          limit: 10,
          page: 1
        }
      })
      init.call(this);
      return false;
    }
    const {list, count} = res;
    this.setState({
      list: list,
      count: count,
      filterCondition: {
        ...this.state.filterCondition,
        page: key
      },
    })
  }).catch(err => {
    message.error(err);
  })
}
/**
 * @function modalPagination - 模态框列表，对数据进行重新计算 && 催动页面渲染
 * @param {String} url - 接口路径
 * @param {modalName} modalName - 操作的模态框名称
 * @param {Number} key - 当前请求的页面的页码
 * */
export const modalPagination = function(url, modalName, key) {
  request('GET', url, {
    ...this.state[modalName].filterCondition,
    page: key
  }).then(res => {
    const { list, count } = res
    this.setState({
      [modalName]: {
        ...this.state[modalName],
        data: list,
        count,
        filterCondition: {
          ...this.state[modalName].filterCondition,
          page: key
        },
      }
    })
  }).catch(err => {
    message.error(err)
  })
}


/**
 * @function updateItem - 公共页面跳转详情页方法
 * @param {String | Number} id - 跳转时候params所携带的参数
 * @param {String} url - 跳转的页面类型
 * @param {Object} data -  跳转时是否携带数据，可选项，默认为空对象
 * */

export const updateItem = function (id, url, data) {
  this.props.history.push({
    pathname : `/${url}/${this.state.type}/${id ? id : '0'}`,
    state: data || {}
  });
}

/**
 * @function deleteItem - 列表页删除某条数据的方法
 * @param {Number} id - 删除时候提供给服务器的ID参数
 * @param {String} url - 请求链接
 * @param {Object} event - 按钮事件
 * */

export const deleteItem = function (id, url, event) {
  if(event){
    event.stopPropagation();
  }
  if(url){
    request('GET', url, {
      id
    }).then(res => {
      message.error('删除成功');
      init.call(this);
    }).catch(err => {
      message.error(err);
    })
  }
  else{
    message.error('功能暂不可用')
  }
}

/**
 * @function submitForm - 提交表单方法
 * @param {Object} submitInfos - 格式化后所需要提交的表单信息
 * @param {String} url - 请求的接口链接
 * @param {String }link - 编辑成功后跳转的链接，默认为空
 * */

export const submitForm = function(submitInfos, url, link = ''){
  request('POST', url, {
    ...submitInfos
  }).then(res => {
    message.success('提交成功');
    if(link){
      this.props.history.push(link);
    }
    this.hideModal();
    this.init();
  }).catch(err => {
    message.error(err);
  })
}

/**
 * @function createThrottle - 创建一个节流函数的工厂方法
 * @param {Number} delay - 延迟执行的时间
 * @return {function} 可执行的节流函数
 * */
export const createThrottle = (delay = 1000) => {
  let STATUS = 'START';
  return function throttle(fn){
    if(STATUS === 'WAITTING'){
      return
    }
    STATUS = 'WAITTING';
    setTimeout(()=>{
      fn && fn();
      STATUS = 'START';
    }, delay)
  }
}

/**
 * @function createDebounce 创建去抖函数
 * @param {Number} delay - 延迟执行时间但是：ms,默认值1000
 * @return {function} - 返回一个可供执行的去抖函数
 * */
export const createDebounce = (delay = 1000) => {
  let timer = null;
  return function debounce(fn){
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn && fn()
    }, delay)
  }
}

/**
 * @function forMateIntro - 替换传入的内容中的;替换为<br /> 用于前端换行
 * @param {Array} list - 需要置换的字符创组成的数组
 * @return {Array} 替换后的数组
 * */
export const forMateIntro = (list) => {
  return list.map(data => {
    data.intro = data.intro.replaceAll('<br/>', ';');
    return data
  })
}
/**
 * @function clearObject - 清空传入对象的内容
 * @param {Object} obj - 需要置空的对象
 * @return {Object} - 返回清空后的对象
 * */
export const clearObject = (obj) => {
  for(let key in obj){
    if(obj.hasOwnProperty(key)){
      if(typeof obj[key] === 'boolean'){
        obj[key] = false;
      }
      else{
        obj[key] = undefined;
      }
    }
    else{
      return
    }
  }
  return obj;
}
/**
 * @function getTime - 返回初始化后的当前时间，格式YY-MM-DD HH:MM::SS
 * @return {String} 返回格式化后的时间字符串，格式YY-MM-DD HH:MM::SS
 * */
export const getTime = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}
/**
 * @function getTime - 返回初始化后的当前时间，格式YY-MM-DD
 * @return {String} 返回格式化后的时间字符串，格式YY-MM-DD
 * */
export const initTime = () => {
  let date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
}
/**
 * @function search - 关键字搜索
 * @param {Object} data - 提交的数据
 * @param {String} url - 请求链接
 * @param {Array | String} time - 选中的时间区间
 * */
export const search = function(data, url, time){
  if(time && time instanceof Array){
    data['leftDate'] = this.state.time[0];
    data['rightDate'] = this.state.time[1];
  }
  else{
    data.time = time
  }
  request('GET', url, {
    ...data
  }).then(res => {
    if(res.list.length === 0){
      message.error('无符合条件内容,请重新输入检索条件');
      this.init();
    }
    else{
      this.setState({
        list: res.list,
        count: res.count,
        pageCount: 1
      })
    }
  }).catch(err => {
    message.error(err);
  })
}
/**
 * @function downloadExcel 下载excel 文件方法
 * @param {String} requestType - 请求类型 (GET || POST)
 * @param {String} url - 请求链接
 * @param {String} fileName - 下载文件名称
 * @param {Object} data - 携带参数
 * */
export const downloadExcel = function(requestType ,url, fileName, data = {}){
  if(this.state.time.length === 0){
    message.error('请选择时间区间后再下载');
    return;
  }
  axios({
    method: requestType,
    url,
    params: {
      ...data
    },
    responseType: 'blob'
  }).then(res => {
    const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
    const aEle = document.createElement('a');     // 创建a标签
    const href = window.URL.createObjectURL(blob);       // 创建下载的链接
    aEle.href = href;   // a标签赋值下载链接
    aEle.download = fileName;  // 下载后文件名
    document.body.appendChild(aEle);
    aEle.click();     // 点击下载
    document.body.removeChild(aEle); // 下载完成移除元素
    window.URL.revokeObjectURL(href) // 释放掉blob对象
  })
}

/**
 * @function 设置表单数据
 * @param {Object} formRef - 获取要操作的form对象
 * @param {Object} data - 要设置的数据
 * */
export const setData = function (formRef, data){
  if(formRef.current){
    formRef.current.setFieldsValue({
      ...data
    })
  }
  else{
    setTimeout(() => {
      formRef.current.setFieldsValue({
        ...data
      })
    }, 500);
  }
}
/**
 * @function 清空表单数据
 * @param {Object} formRef - 获取要操作的form对象
 * */
export const clearData = function (formRef){
  formRef.current.setFieldsValue({
    ...clearObject(formRef.current.getFieldsValue())
  });
}

/**
 * @function 生成页面上对应ID的内容
 * @param {String} id - 获取的ID名称
 * */

export const drawOrderDetail = (id) => {
  let scrollY = document.body.scrollTop;
  let scrollX =document.body.scrollLeft;
  let el = document.getElementById(id);
  let canvas = document.createElement('canvas');
  let target = document.getElementById(id);
  let style = getComputedStyle(target);
  let width = Number.parseFloat(style.width);
  let height = Number.parseFloat(style.height);
  canvas.width = width * 3;
  canvas.height = height * 3;
  html2canvas(el,{
    // allowTaint: true,
    scale: 3,
    canvas,
    useCORS: true,
    scrollY:-scrollY,
    scrollX:-scrollX
  }).then(function(canvas) {
    const aEle = document.createElement('a');
    aEle.href = canvas.toDataURL('image/png');
    aEle.download = initTime() + '.png';
    document.body.appendChild(aEle);
    aEle.click();
    document.body.removeChild(aEle);
  });
}
/**
 * @function 关闭模态框
 * @param {String} modalName - 模态框管理对象名称
 * @param {Object || Array} data - 要设置到表单中的数据
 * @param {Boolean} isEdit - 是否编辑
 * @param {function} method - 打开时候的回调函数
 * */
export const showModal = function(modalName, data, isEdit, method = () => {}){
  if(data && this.state[modalName].formRef){
    setData(this.state[modalName].formRef, data);
  }
  this.setState({
    [modalName] : {
      ...this.state[modalName],
      visible: true,
      isEdit,
      data
    }
  })
  if(typeof method === 'function'){
    method();
  }
}
/**
 * @function 关闭模态框
 * @param {String} modalName - 模态框管理对象名称
 * @param {Object} clearData - 关闭时候要清空的额外数据
 * */
export const hideModal = function(modalName, clearData = {}){
  this.setState({
    [modalName] : {
      ...this.state[modalName],
      visible: false,
      data: this.state[modalName].initData,
      ...clearData
    }
  })
  if(this.state[modalName].formRef){
    this.state[modalName].formRef.current.resetFields();
  }
}

/**
 * @function 提交模态框中的数据
 * @param {String} url - 请求的接口链接
 * @param {Object} data - 要提交服务端的数据
 * @param {String} modalName - 模态框管理对象名称
 * @param {function} method - 操作成功后执行的操作
 * @param {Object} clearData - 关闭时候要清空的额外数据
 * */
export const submitModalForm = function(url, data , modalName, clearData = {}, method = function(){}){
  request('POST', url, data).then(res => {
    message.success('操作成功');
    hideModal.call(this, modalName, {
      ...clearData
    });
    init.call(this);
    if(typeof method === 'function'){
      method && method();
    }
  }).catch(err => {
    message.error(err);
  })
}


/**
 * @function 提交模态框中的数据
 * @param {String} url - 请求的接口链接
 * @param {String} modalName - 模态框管理对象名称
 * @param {Object} data - 要提交服务端的数据
 * */
export const showList = function(url ,modalName, data) {
  request('GET', url, {
    ...this.state[modalName].filterCondition,
    ...data
  }).then(res => {
    const { list , count } = res;
    this.setState({
      [modalName]: {
        ...this.state[modalName],
        filterCondition: {
          ...this.state[modalName].filterCondition,
          ...data
        },
        count
      }
    })
    showModal.call(this, modalName, list);
  }).catch(err => {
    message.error(err)
  })
}

