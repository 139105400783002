import { createStore ,applyMiddleware, combineReducers} from 'redux';
import config from './config';
// import dict from './dict';
import thunk from "redux-thunk";

const store = createStore(combineReducers({
  config
  // dict
}), applyMiddleware(thunk));

export default store;
