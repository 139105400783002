/**
 * @file 全局配置文件
 * @author 郄晓晖
 * */

module.exports = {
  // 根域名
  BASE_URL:'https://api.qytgf.com/',
  // 请求Oss信息的接口
  getOssInfo:'https://api.qytgf.com/oss/getOssTst',
  OSS:'oss-cn-hangzhou.aliyuncs.com',
  // Oss bucket的名称
  bucket: 'bn-zdxy',
  storageName: 'zdxy',
  loginTitle: '祛妍堂管理后台',
  footerInfo: '开拓者人力资源后台管理系统 &copy;2021~2121 Created By 丶逆风独行',
  // 接口地图
  requestMap:{
    Common: {
      editPassWord: '/index/EditPsd',
      loginOut: '/index/out',
      login: '/index/accountLogin'
    },
    Login: {
      login: '/index/accountLogin'
    },
    About: {
      inquire: '/company/getDetail',
      edit: '/company/editCompany'
    },
    // Banner
    Banner: {
      inquire: '/banner/getList',
      add: '/banner/addBanner',
      edit: '/banner/editBanner',
      delete: '/banner/delBanner'
    },
    // 人才招聘
    Recruitment: {
      inquire: '/recruit/getList',
      detail: '/recruit/getDetail',
      add: '/recruit/addRecruit',
      edit: '/recruit/editRecruit',
      delete: '/recruit/delRecruit'
    },
    // 仪器管理
    Instrument: {
      inquire: '/instrument/getList',
      detail: '/instrument/getDetail',
      add: '/instrument/addInstrument',
      edit: '/instrument/editInstrument',
      delete: '/instrument/delInstrument'
    },
    // 商品管理
    Product: {
      inquire: '/product/getList',
      add: '/product/addProduct',
      edit: '/product/editProduct',
      delete: '/product/delProduct',
      detail: '/product/getDetail'
    },
    // 商品类型
    ProductType: {
      inquire: '/product/getTypeList',
      add: '/product/addType',
      edit: '/product/editType',
      delete: '/product/delType'
    },
    // 商学院
    CommerceAcademy: {
      inquire: '/college/getDetail',
      edit: '/college/editCollege'
    },
    // 服务团队
    ServiceTeam: {
      inquire: '/service/getTeamList',
      detail: '/service/getTeamDetail',
      add: '/service/addTeam',
      edit: '/service/editTeam',
      delete: '/service/delTeam'
    },
    // 服务项目
    ServiceItem: {
      inquire: '/service/getItemsList',
      add: '/service/addItems',
      edit: '/service/editItems',
      delete: '/service/delItems'
    },
    // 门店管理
    Store: {
      inquire: '/store/getList',
      detail: '/store/getDetail',
      add: '/store/addStore',
      edit: '/store/editStore',
      delete: '/store/delStore'
    },
    // 热点新闻
    HotNews: {
      inquire: '/article/getList',
      detail: '/article/getDetail',
      add: '/article/addArticle',
      edit: '/article/editArticle',
      delete: '/article/delArticle'
    },
    //留言板管理
    Message: {
      inquire: '/message/getList',
      delete: '/message/delMessage'
    },
    // 招商加盟
    Join: {
      inquire: '/join/getDetail',
      edit: '/join/editJoin'
    },
    Module: {
      inquire: '/menu/getList',
      edit: '/menu/editMenu'
    },
    Question: {
      inquire: '/question/getList',
      add: '/question/addQuestion',
      edit: '/question/editQuestion',
      delete: '/question/delQuestion'
    },
    Technology: {
      inquire: '/technology/getList',
      add: '/technology/addTechnology',
      edit: '/technology/editTechnology',
      delete: '/technology/delTechnology'
    }
  },
  // 腾讯地图开发者KEY
  mapApiKey: 'BYKBZ-BQ3KX-DO34N-T257Z-TFKS5-2LF77'
}